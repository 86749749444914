<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="buttons">
        <router-link v-if="!isLoading && checkPermissions(['b2b_orders_product.view'])" :to="{ name: 'admin.product' }" class="btn btn-add">
          <span>{{ 'B2B product toevoegen' }}</span></router-link>
      </div>
    </header>

    <div class="catalogue-uploads" v-if="catalogueGlobal">
      <formulate-input :disabled="isUploading" :label="$t('B2B catalogus NL')" :uploader="uploadCatalogueNl" class="upload" error-behavior="submit" name="catalogue_file_id" type="file" upload-behavior="live"></formulate-input>
      <formulate-input :disabled="isUploading" :label="$t('B2B catalogus FR')" :uploader="uploadCatalogueFr" class="upload" error-behavior="submit" name="catalogue_file_id" type="file" upload-behavior="live"></formulate-input>
      <formulate-input :disabled="isUploading" :label="$t('B2B catalogus EN')" :uploader="uploadCatalogueEn" class="upload" error-behavior="submit" name="catalogue_file_id" type="file" upload-behavior="live"></formulate-input>
    </div>

    <div class="main-content">
      <div :class="{'loading': isLoading}" class="pane table-pane">
        <div class="table-wrapper">
          <div class="table-element">
            <table v-if="!isLoading" class="listData">
              <thead>
              <tr>
                <th :class="(sortBy === 'product.id' ? 'active ' + sortDir : '')" class="id" @click="sortTable('product.id')">
                  <div>ID</div>
                </th>
                <th :class="(sortBy === 'product.product_code' ? 'active ' + sortDir : '')" @click="sortTable('product.product_code')">
                  <div>Code</div>
                </th>
                <th :class="(sortBy === 'product.name' ? 'active ' + sortDir : '')">
                  <div>Naam</div>
                </th>
                <th :class="(sortBy === 'product.minimal_order_amount' ? 'active ' + sortDir : '')" @click="sortTable('product.minimal_order_amount')">
                  <div>Minimum bestelhoeveelheid</div>
                </th>
                <th :class="(sortBy === 'product.stock' ? 'active ' + sortDir : '')" @click="sortTable('product.stock')">
                  <div>Voorraad</div>
                </th>
                <th :class="(sortBy === 'product.unit_price' ? 'active ' + sortDir : '')" @click="sortTable('product.unit_price')">
                  <div>Prijs per stuk</div>
                </th>
              </tr>
              </thead>

              <tbody v-if="listData.length > 0">
              <tr v-for="listObj in listData" :key="listObj.id" @click="$router.push({name: 'admin.product', params: {id: listObj.id }})" @contextmenu.prevent.stop="productRightClick($event, listObj)">
                <td class="id">{{ listObj.id }}</td>
                <td>{{ listObj.product_code }}</td>
                <td>{{ getTranslation(listObj).name }}</td>
                <td>{{ listObj.minimal_order_amount }}</td>
                <td>{{ listObj.stock }}</td>
                <td>{{ $n(listObj.unit_price, 'currency', 'nl-BE') }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ total.min }} - {{ total.max }} van {{ listTotal }} b2b producten
            </div>

            <div class="buttons">
              <button :disabled="page === 0" class="btn-inverse" @click="prevPage()">Prev</button>
              <button :disabled="listData.length !== perPage" class="btn-inverse" @click="nextPage()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-simple-context-menu ref="vueSimpleContextMenu" :options="contextMenuOptions" element-id="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import PageTitle from '@/components/admin/PageTitle'
import { notification } from '@/notifications'
// import { DateTime } from 'luxon'

export default {
  components: { PageTitle },
  data: function () {
    return {
      catalogueGlobal: null,
      isLoading: true,
      isUploading: false,
      search: '',
      listData: [],
      page: 0,
      perPage: 25,
      sortBy: 'product.created',
      sortDir: 'asc',
      listTotal: 0,
      contextMenuOptions: [
        { id: 'deleteProduct', name: 'B2B product verwijderen' }
      ]
    }
  },
  async mounted () {
    this.isLoading = true
    await this.getListData()
    const res = await ApiService.fetchGlobal('products', 'b2b_catalogue_file_id')
    if (res.status === 200) {
      this.catalogueGlobal = res.data
      console.log(this.catalogueGlobal)
    }
    this.isLoading = false
  },
  methods: {
    async uploadFile (file, lang) {
      this.isUploading = true

      const formData = new FormData()
      formData.append('files', file)
      const res = await ApiService.uploadFiles(formData)

      if (res.status === 200) {
        this.isUploading = false
        const translationIndex = this.catalogueGlobal.translations.findIndex(translation => translation.lang_code === lang)
        if (translationIndex >= 0) {
          this.catalogueGlobal.translations[translationIndex].value = res.data[0]
        } else {
          const translation = {
            lang_code: lang,
            value: res.data[0]
          }

          this.catalogueGlobal.translations.push(translation)
        }

        await ApiService.patchGlobal('products', 'b2b_catalogue_file_id', this.catalogueGlobal)
      }
    },
    async uploadCatalogueNl (file) {
      await this.uploadFile(file, 'nl')
    },
    async uploadCatalogueFr (file) {
      await this.uploadFile(file, 'fr')
    },
    async uploadCatalogueEn (file) {
      await this.uploadFile(file, 'en')
    },
    async getListData () {
      await ApiService.fetchProducts({
        page: this.page,
        order_by: this.sortBy,
        order_dir: this.sortDir
      }).then(res => {
        this.listData = res.data.products
        this.listTotal = res.data.total
      })
    },
    async sortTable (sortBy) {
      if (sortBy === this.sortBy) {
        this.sortDir = (this.sortDir === 'asc' ? 'desc' : 'asc')
      }

      this.sortBy = sortBy

      await this.getListData()
    },
    prevPage: async function () {
      if (this.page > 0) {
        this.page--
        await this.getListData()
      }
    },
    nextPage: async function () {
      if (this.listData.length === this.perPage) {
        this.page++
        await this.getListData()
      }
    },
    productRightClick: async function (event, product) {
      if (this.checkPermissions(['b2b_orders_product.edit'])) {
        this.$refs.vueSimpleContextMenu.showMenu(event, product)
      }
    },
    contextOptionClicked: async function (event) {
      await this.deleteProduct(event.item.id)
    },
    async deleteProduct (productId) {
      const res = await ApiService.deleteProduct(productId)
      if (res.status === 200) {
        notification('B2B product succesvol verwijderd!')
        await this.getListData()
      }
    }
  },
  computed: {
    total: function () {
      const offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      return {
        min: offsetMin,
        max: offsetMax > this.listTotal ? this.listTotal : offsetMax
      }
    }
  }
}
</script>
